/* eslint-disable @wordpress/dependency-group */
import $ from 'jquery';
/* eslint-disable @wordpress/dependency-group */
import cube from './cubes';

const slider = $("[rel='hero-slider']"),
	featuredImage = $("[rel='featured-image']");

// slider.on('init', function(event, slick) {
// 	const slideCube = slick.$slides[0];
// 	setTimeout(function() {
// 		cube(slideCube);
// 	}, 500);
// });

slider.slick({
	dots: true,
	autoplaySpeed: 5000,
	autoplay: true,
	adaptiveHeight: true,
});
// slider.slick('refresh');

slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
	const slideCube = slick.$slides[nextSlide];
	// console.log('slideCube', slideCube);
	cube(slideCube);
});

if (featuredImage.length) {
	// console.log('featuredImage', featuredImage);
	setTimeout(function() {
		cube(featuredImage[0]);
		// console.log('this one?', featuredImage.length);
	}, 500);

	// let id;
	// $(window).resize(function() {
	// 	clearTimeout(id);
	// 	id = setTimeout(doneResizing, 500);
	// });
	//
	// function doneResizing() {
	// 	cube(featuredImage[0]);
	// 	// console.log('resize');
	// }
}
